<template>
	<div id="subject" v-loading="loading">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>管理</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">课程管理</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="nav-tab-bar-box u-f-item u-f-jsb">
				<div class="nav-tab-bar u-f-item">
					<div class="font_14 nav-tab-item" @click="twacherTap(index)" :class="index == gradeIndex ? 'active' : ''" v-for="(item, index) in gradeMenu" :key="index">
						{{ item.name }}
					</div>
				</div>
			</div>
			<div class="content">
				<div class="title u-f-item u-f-jsb">
					<div>
						共{{total}}条数据
					</div>
					<div class="u-f-item">
						<el-button size="small" @click="openShow(1)">添加类型</el-button>
					</div>
				</div>
				<el-table
					:data="subjectTypeList"
					height="66vh"
					ref="multipleTable"
					tooltip-effect="dark"
					style="width: 100%;border: 1px solid #EEEEEE;"
				>
					<el-table-column type="index" label="序号" width="70" align="center"></el-table-column>
					<el-table-column prop="code" label="课程类型代码"  align="center"></el-table-column>
					<el-table-column prop="name" label="课程类型名称"  align="center"></el-table-column>
					<el-table-column label="基本类型"  align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.type==1">必修课</span>
							<span v-if="scope.row.type==2">专业选修课</span>
							<span v-if="scope.row.type==3">公共选修课</span>
							<span v-if="scope.row.type==4">实践环节</span>
						</template>
					</el-table-column>
					<el-table-column prop="weight" label="绩点权重"  align="center"></el-table-column>
					<el-table-column prop="course_coefficient" label="课程系数"  align="center"></el-table-column>
					<el-table-column label="操作" width="150" align="center">
						<template slot-scope="scope">
							<el-button @click="openDialog(2, scope.row)" type="text" size="small">编辑</el-button>
							<el-button @click="delSubject(scope.row.id)" type="text" size="small" style="color: red;">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div class="u-f-right" style="padding: 15px 0;">
					<el-pagination
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
						:current-page.sync="page"
						:page-size="limit"
						layout="total, prev, pager, next"
						:total="total"
					></el-pagination>
				</div>
			</div>
			<!-- 添加学科 -->
			<el-dialog :title="showTitle" :visible.sync="addSubject" width="600px" :before-close="handleClose">
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							课程类型代码
						</div>
						<el-input v-model="code" placeholder="请输入课程类型代码" style="width: 75%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							课程类型名称
						</div>
						<el-input v-model="name" placeholder="请输入课程类型名称" style="width: 75%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							基本类型
						</div>
						<el-radio-group v-model="type" style="width: 75%;" size="medium">
						    <el-radio-button :label="1">必修课</el-radio-button>
						    <el-radio-button :label="2">专业选修课</el-radio-button>
						    <el-radio-button :label="3">公共选修课</el-radio-button>
							<el-radio-button :label="4">实践环节</el-radio-button>
						</el-radio-group>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							绩点权重
						</div>
						<el-input v-model="weight" placeholder="请输入绩点权重" style="width: 75%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							课程系数
						</div>
						<el-input v-model="course_coefficient" placeholder="请输入课程系数" style="width: 75%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							是否公共必修课
						</div>
						<el-radio-group v-model="status" size="medium">
							<el-radio-button :label="1">是</el-radio-button>
							<el-radio-button :label="0">否</el-radio-button>
						</el-radio-group>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="closeShow" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="submit">确 定</el-button>
				</span>
			</el-dialog>
		</index>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
export default {
	components: {
		index
	},
	data() {
		return {
			gradeMenu: [
				{ name: '课程列表' },
				{ name: '课程类型' }
			],
			gradeIndex:1,
			subjectTypeList: [],
			addSubject: false,
			loading: false,
			editid: '',
			page:1,
			limit:10,
			total:0,
			status:1,
			type:1,
			course_coefficient:'',
			code:'',
			name:'',
			weight:'',
			showTitle:'添加课程类型列表',
			showType:1
		};
	},
	mounted() {
		this.getInfo();
	},
	methods: {
		closeShow(){
			this.init()
			this.addSubject = false;
		},
		openShow(type){
			this.showType = type;
			this.showTitle = '添加课程类型列表';
			this.addSubject = true;
		},
		openDialog(type,item){
			this.showType = type;
			this.showTitle = '编辑课程类型列表';
			this.editid = item.id;
			this.status = item.status;
			this.type = item.type;
			this.course_coefficient = item.course_coefficient;
			this.code = item.code;
			this.name = item.name;
			this.weight = item.weight;
			this.addSubject = true;
		},
		init(){
			this.editid = '';
			this.status = 1;
			this.type = 1;
			this.course_coefficient = '';
			this.code = '';
			this.name = '';
			this.weight = '';
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			this.page = val;
			this.getInfo()
		},
		twacherTap(index){
			this.gradeIndex = index;
			if(index==0){
				this.$router.push('/manageSubject')
			}else if(index==1){
				this.$router.push('/subjectType')
			}
		},
		stopDefault(e) {
			e.stopPropagation();
		},
		// 课程分类列表
		getInfo() {
			this.$api.setting.subjectTypeList({
				page:this.page,
				limit:this.limit
			}).then(res => {
				if (res.data.code == 1) {
					let list = res.data.data.rows;
					this.total = res.data.data.total;
					this.subjectTypeList = list;
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		// 关闭弹窗
		handleClose(done) {
			this.init()
			done();
		},
		// 添加学科
		submit() {
			if(this.showType==1){
				this.addSubjectType()
			}else {
				this.edit()
			}
		},
		addSubjectType(){
			if (this.code == '' || !this.code) {
				return this.$message.error('请输入课程分类代码');
			}
			if (this.name == '' || !this.name) {
				return this.$message.error('请输入课程分类名称');
			}
			if (this.weight == '' || !this.weight) {
				return this.$message.error('请输入绩点权重');
			}
			this.$api.setting.addSubjectType({
				code:this.code, 	
				name:this.name,
				type:this.type,
				weight:this.weight,
				course_coefficient:this.course_coefficient, 	
				status:this.status
			}).then(res => {
				if (res.data.code == 1) {
					this.$message.success('添加成功');
					this.init()
					this.addSubject = false;
					this.getInfo();
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		edit() {
			if (this.code == '' || !this.code) {
				return this.$message.error('请输入课程分类代码');
			}
			if (this.name == '' || !this.name) {
				return this.$message.error('请输入课程分类名称');
			}
			if (this.weight == '' || !this.weight) {
				return this.$message.error('请输入绩点权重');
			}
			this.$api.setting.editSubjectType({
				id:this.editid,
				code:this.code, 	
				name:this.name,
				type:this.type,
				weight:this.weight,
				course_coefficient:this.course_coefficient, 	
				status:this.status
			}).then(res => {
				if (res.data.code == 1) {
					this.$message.success('编辑成功');
					this.init()
					this.addSubject = false;
					this.getInfo();
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		// 删除学科
		delSubject(id) {
			this.$confirm('确定要删除？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消'
			}).then(() => {
				this.$api.setting
					.delSubjectType({
						id: id
					})
					.then(res => {
						if (res.data.code == 1) {
							this.$message.success('删除成功');
							this.getInfo();
						} else {
							this.$message.error(res.data.msg);
						}
					});
			});
		}
	}
};
</script>

<style lang="scss">
#subject {
	.nav-tab-bar-box {
		background-color: #ffffff;
		padding: 0.52vw;
		margin-top: 1.04vw;
		.nav-tab-bar {
			.nav-tab-item {
				color: #6e7a87;
				padding: 0 0.78125vw;
				border-right: 1px solid #f3f3f3;
				cursor: pointer;
				-webkit-touch-callout: none;
				-webkit-user-select: none;
				-khtml-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}
			&div:last-child {
				border-right: none;
			}
			.active {
				font-size: 0.833vw;
				color: #63acff;
				position: relative;
				font-weight: bold;
			}
			.active::after {
				position: absolute;
				content: '';
				height: 2px;
				left: 0.78125vw;
				right: 0.78125vw;
				background-color: #63acff;
				bottom: -0.6vw;
			}
		}
	}
	.content {
		margin-top: 10px;
		background-color: #ffffff;
		height: 80vh;
		padding: 0 25px;
		.title {
			padding: 15px 0 15px 0;
		}
		.el-table{
			table-layout: fixed;
		}
		.el-table th > .cell,
		.el-table .cell {
			overflow: hidden; /*超出部分隐藏*/
			white-space: nowrap; /*不换行*/
			text-overflow: ellipsis; /*超出部分文字以...显示*/
		}
		.el-table thead {
			background: #fafafa !important;
		}
		.el-table th {
			padding: 15px 5px !important;
			background: #fafafa !important;
		}
		.el-table::before {
			left: 0;
			bottom: 0;
			width: 100%;
			height: 0;
		}
		.el-table .btn{
			padding: 5px 10px;
			text-align: center;
			font-size: 12px;
		}
	}
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0 20px 35px 20px;
		.edit-box {
			.input-box {
				margin-top: 35px;
				padding-left: 15px;
				.input-lebal {
					color: #aeb5b8;
					margin-right: 10px;
					span {
						color: #ff7a65;
						margin-right: 3px;
					}
				}
				.el-icon-remove-outline {
					font-size: 1.5625vw;
					color: #d7d9db;
					margin-left: 10px;
				}
			}
			.avatar-uploader .el-upload {
				border: 1px dashed #d9d9d9;
				border-radius: 6px;
				cursor: pointer;
				position: relative;
				overflow: hidden;
			}
			.avatar-uploader .el-upload:hover {
				border-color: #409eff;
			}
			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				width: 90px;
				height: 90px;
				line-height: 90px;
				text-align: center;
			}
			.avatar {
				width: 90px;
				height: 90px;
				display: block;
			}
		}
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
}
</style>
